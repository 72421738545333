import React from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { BASE_URL, redirect } from "../constants/config";
import { useEffect } from "react";
import Header from "../components/Header";
import { partners, proAdvant } from "../assets/data";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";
import moment from "moment/moment";
import Footer from "../components/Footer";

const Home = () => {
  const navigate = useNavigate();

  const [count, setCount] = useState([]);
  const [members, setMembers] = useState([]);
  const [newsPress, setNewsPress] = useState([]);

  const [loading, setloading] = useState(false);

  const smallScreen = useMediaQuery("(max-width:767px)");
  const isMobile = useMediaQuery("(max-width:850px)");
  const largest = useMediaQuery("(min-width:1250px)");

  const [team, setTeam] = useState([]);

  const TeamData = () => {
    axios
      .get(`${BASE_URL}/api/ourTeam/getTeam`)
      .then((res) => {
        setTeam(res.data.team);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCountOfCompanies = () => {
    setloading(true);
    axios
      .get(`${BASE_URL}/api/companies/count`)
      .then((res) => {
        setCount(res.data);
        setloading(false);
      })
      .catch((e) => {
        console.log(e);
        setloading(false);
      });
  };

  const getMemberNews = () => {
    setloading(true);
    axios
      .get(`${BASE_URL}/api/newsandpress/get_all_member_press`)
      .then((res) => {
        setMembers(res.data.member_press);
        setloading(false);
      })
      .catch((e) => {
        console.log(e);
        setloading(false);
      });
  };

  const getNewsPress = () => {
    setloading(true);
    axios
      .get(`${BASE_URL}/api/newsandpress/get_all_news_press`)
      .then((res) => {
        setNewsPress(res.data.news_press);
        setloading(false);
      })
      .catch((e) => {
        console.log(e);
        setloading(false);
      });
  };

  useEffect(() => {
    getCountOfCompanies();
    getMemberNews();
    getNewsPress();
    TeamData();
  }, []);

  const CustomPrevButton = (props) => {
    const { onClick } = props;
    return (
      <button
        className="custom-prev-button"
        style={{ outline: "none" }}
        onClick={onClick}
      >
        <ArrowBackIosIcon fontSize="large" style={{ fontSize: "3rem" }} />
      </button>
    );
  };

  const CustomNextButton = (props) => {
    const { onClick } = props;
    return (
      <button
        className="custom-next-button"
        style={{ outline: "none" }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon fontSize="large" style={{ fontSize: "3rem" }} />
      </button>
    );
  };

  const CustomPrevButtonM = (props) => {
    const { onClick } = props;
    return (
      <button
        className="custom-prev-button"
        style={{ left: !isMobile && "-5%", outline: "none" }}
        onClick={onClick}
      >
        <ArrowBackIosIcon fontSize="large" style={{ fontSize: "3rem" }} />
      </button>
    );
  };

  const CustomNextButtonM = (props) => {
    const { onClick } = props;
    return (
      <button
        className="custom-next-button"
        style={{ right: !isMobile && "-5%", outline: "none" }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon fontSize="large" style={{ fontSize: "3rem" }} />
      </button>
    );
  };

  const firstBanner = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 200,
    dots: false,
    arrows: true,
    prevArrow: <CustomPrevButton />,
    nextArrow: <CustomNextButton />,
    fade: false,
  };

  const proAdv = {
    dots: true,
    infinite: false,
    autoplay: true,
    arrows: true,
    prevArrow: <CustomPrevButtonM />,
    nextArrow: <CustomNextButtonM />,
    speed: 300,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const newsNpress = {
    arrows: false,
    centerMode: true,
    centerPadding: "120px",
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
  };

  const membersSetting = {
    dots: false,
    arrows: false,
    prevArrow: <CustomPrevButtonM />,
    nextArrow: <CustomNextButtonM />,
    infinite: true,
    autoplay: true,
    speed: 4000,
    slidesToShow: 5,
    rows: 2,
    slidesToScroll: 1,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: true,

    // speed: 50,
    // slidesToShow: 5,
    // rows: 2,
    // slidesToScroll: 1,
    // autoplaySpeed: 10000,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const partnersSetting = {
    dots: false,
    arrows: true,
    prevArrow: <CustomPrevButtonM />,
    nextArrow: <CustomNextButtonM />,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleClick = (div) => {
    setTimeout(() => {
      const targetDiv = document.getElementById(`${div}`);
      if (targetDiv) {
        // const headerHeight = document.querySelector('header').offsetHeight;
        targetDiv.scrollIntoView({ behavior: "smooth", block: "start" });
        window.scrollTo(0, targetDiv.offsetTop - 100);
      }
    }, 100);
  };

  const limitWords = (value, max) => {
    if (!value) {
      return "...";
    }
    return value.length > max ? value.substr(0, max) + "..." : value;
  };

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <link rel="stylesheet" href={require("../theme/home.css")} />
      <link rel="stylesheet" href={require("../theme/animate.css")} />
      <link rel="stylesheet" href={require("../theme/bootstrap.min.css")} />
      <link rel="stylesheet" href={require("../theme/hover-min.css")} />
      <link rel="stylesheet" href={require("../theme/slick.css")} />
      <link rel="stylesheet" href={require("../theme/slick-theme.css")} />
      <link rel="stylesheet" href={require("../theme/style.css")} />

      <Header />

      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Website will be open on 25th October 2024
        </Alert>
      </Snackbar>

      <section class="first-banner">
        <Slider
          {...firstBanner}
          className="row first-banner-slider"
          style={{ marginLeft: "0px", marginRight: "0px" }}
        >
          <div class="slide_div1">
            <div class="main_banner">
              <div class="first-banner-img">
                <img
                  src={require("../assets/images/home/Thames Marine 1.jpg")}
                  alt=""
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  zIndex: 99,
                  bottom: smallScreen ? "10%" : "12.5%",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <a
                  href="#"
                  class="themeBtn"
                  onClick={() => navigate("/insurance-inquiry")}
                >
                  Try It Now
                </a>
              </div>
            </div>
          </div>
          <div class="slide_div2">
            <div class="main_banner">
              <div class="first-banner-img">
                <img
                  src={require("../assets/images/4th-conference.jpg")}
                  alt=""
                />
              </div>

              <div
                style={{
                  position: "absolute",
                  zIndex: 99,
                  bottom: smallScreen ? "10%" : "12.5%",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <a
                  href="#"
                  class="themeBtn"
                  onClick={() =>
                    window.open(
                      "http://www.conference.prolognet.com/",
                      "_blank"
                    )
                  }
                >
                  Register
                </a>
              </div>
            </div>
          </div>
          <div class="slide_div2">
            <div class="main_banner">
              <div class="first-banner-img">
                <img
                  src={require("../assets/images/home/first-banner.png")}
                  alt=""
                />
              </div>
              <div class="first-banner-txt">
                <p
                  class="content-para"
                  style={{
                    fontWeight: "700",
                    fontSize: smallScreen ? "12px" : "20px",
                    textDecoration: "underline !important",
                  }}
                >
                  The Smartest SEMI-Exclusive Logistics Network
                </p>
                <h2>
                  The <span>NEXT-GEN</span>
                  <br />
                  LOGISTICS NETWORK
                </h2>
                <p
                  class="content-para"
                  style={{
                    fontWeight: "800",
                    fontSize: largest ? "24px" : isMobile ? "12px" : "20px",
                    textDecoration: "underline !important",
                    textShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
                  }}
                >
                  NETWORKING - REDEFINED
                </p>
                <div class="first-banner-btn">
                  <a
                    href="#"
                    class="themeBtn"
                    onClick={() => navigate("/request-demo")}
                  >
                    Request Demo
                  </a>
                  <a
                    href="#"
                    class="themeBtn-light"
                    onClick={() => window.open(redirect)}
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="slide_div3">
            <div class="main_banner">
              <div class="first-banner-img">
                <img
                  src={require("../assets/images/App.png")}
                  alt=""
                  style={{ height: !smallScreen && "90vh" }}
                />
              </div>
              {!smallScreen && (
                <>
                  <div
                    style={{
                      zIndex: "9999999",
                      position: "absolute",
                      bottom: "25%",
                      left: "6%",
                      width: "13.5%",
                      height: "6.5%",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      color: "white",
                    }}
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/us/app/professional-logistics-network/id6446221546?platform=iphone",
                        "_blank"
                      )
                    }
                  />
                  <div
                    style={{
                      zIndex: "9999999",
                      position: "absolute",
                      bottom: "25%",
                      left: "21%",
                      width: "13.5%",
                      height: "6.5%",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      color: "white",
                    }}
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.nucleus.prolognet",
                        "_blank"
                      )
                    }
                  />
                </>
              )}
            </div>
          </div>

          <div class="slide_div3">
            <div class="main_banner">
              <div class="first-banner-img">
                <img
                  src={require("../assets/images/home/first-banner-3.png")}
                  alt=""
                />
              </div>
              <div class="first-banner-txt">
                <h2>
                  {" "}
                  <span> PRO-Tect </span> - Smooth, <br /> Smart , Simple.
                </h2>
                <p class="content-para">
                  Our Insurance Products and Services offering through world
                  class preferred brokers
                </p>
                <div class="first-banner-btn">
                  <a
                    href="#"
                    class="themeBtn-light"
                    onClick={() => navigate("/request-demo")}
                  >
                    Request Demo
                  </a>
                  <a
                    href="#"
                    class="themeBtn"
                    onClick={() => window.open(redirect)}
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section>

      {/* <!-- first Banner CSS End --> */}

      {/* <!-- Our Network Section Start  --> */}
      <section class="second-banner">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
              <div class="second-banner-txt">
                <h2 class="content-title">Our network</h2>
                <p class="content-para">
                  PROFESSIONAL LOGISTICS NETWORK is a digitally advanced
                  Next-GEN alliance of freight forwarders and logistics
                  providers. The top of the line network with some unparalleled
                  benefits and features that NO other network has as of today.
                </p>
                <p class="content-para">
                  We have only the best of the global small and medium sized
                  members in our network who are experts in their domain.
                </p>
                <a
                  href="#"
                  class="themeBtn"
                  onClick={() =>
                    window.open("http://app.staging.prolognet.com/signup")
                  }
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Our Network Section End  --> */}

      {/* <!-- members cities countries section start --> */}
      <section class="members-cities">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <div class="social-detail">
                <div class="social-member">
                  <img
                    src={require("../assets/images/home/members.png")}
                    alt=""
                  />
                  <div class="member-txt">
                    <h3>{count.members}</h3>
                    <p style={{}}>Member Offices</p>
                  </div>
                </div>
                <div class="social-member">
                  <img
                    src={require("../assets/images/home/cities.png")}
                    alt=""
                  />
                  <div class="member-txt">
                    <h3>{count.city}</h3>
                    <p>Cities</p>
                  </div>
                </div>
                <div class="social-member">
                  <img
                    src={require("../assets/images/home/countries.png")}
                    alt=""
                  />
                  <div class="member-txt">
                    <h3>{count.country}</h3>
                    <p>Countries</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- members cities countries section End --> */}

      {/* <!-- Business at your fingertips Section Start --> */}
      <section class="third-banner">
        <h2 class="content-title">Business at your fingertips</h2>

        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="bussiness-conten-box">
                <img
                  src={require("../assets/images/home/buss-box-1.png")}
                  alt=""
                />
                <p>
                  Send and receive money between PRO members with negligible
                  transfer fees.
                </p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="bussiness-conten-box">
                <img
                  src={require("../assets/images/home/buss-box-2.png")}
                  alt=""
                />
                <p>
                  Strategic partners with amazing discounts helping forwarders
                  excel further.
                </p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="bussiness-conten-box">
                <img
                  src={require("../assets/images/home/buss-box-3.png")}
                  alt=""
                />
                <p>Able to generate leads and grab potential business.</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="bussiness-conten-box">
                <img
                  src={require("../assets/images/home/buss-box-4.png")}
                  alt=""
                />
                <p>
                  Send and receive RFQs to & from multiple forwarders in 3
                  simple steps.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Business at your fingertips Section End --> */}

      {/* <!-- Unique Features Section Start --> */}
      <section class="unique-features">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div
                class="unique-txt"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <h2 class="content-title">Unique Features</h2>
                <div
                  class="row"
                  style={{ flexWrap: "inherit", padding: "0px" }}
                >
                  <div class="col-lg-4">
                    <div class="unique-btn">
                      <a
                        href="#"
                        class="themeBtn-light"
                        onClick={() => navigate("/request-demo")}
                      >
                        Request Demo
                      </a>
                      <a
                        href="#"
                        class="themeBtn"
                        onClick={() => window.open(redirect)}
                      >
                        Apply Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="unique-short-txt">
                <a style={{ color: "#fff" }}>
                  Mobile App for faster and efficient service to fellow members
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="unique-short-txt">
                <a style={{ color: "#fff" }}>
                  Personalized service to each member
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="unique-short-txt">
                <a style={{ color: "#fff" }}>Who viewed my profile feature</a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="unique-short-txt">
                <a style={{ color: "#fff" }}>
                  Smart search function with multiple real time filters
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="unique-short-txt">
                <a style={{ color: "#fff" }}>
                  World Class Insurance products and services
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="unique-short-txt">
                <a style={{ color: "#fff" }}>
                  A completely menuless smart dashboard
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="unique-short-txt">
                <a style={{ color: "#fff" }}>Fully customizable dashboard</a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="unique-short-txt">
                <a style={{ color: "#fff" }}>Self serve smart portal</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Unique Features Section End --> */}

      {/* <!-- Pro Advantages Section Start --> */}
      <section class="pro-advantage" id="pro-ad">
        <h2 class="content-title" style={{ letterSpacing: "-1px" }}>
          PRO-Advantages
        </h2>
        <div
          class="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Slider {...proAdv} className="row one-time">
            {proAdvant.map((item, index) => {
              return (
                <div
                  key={index}
                  class="col-lg-4"
                  onClick={() => {
                    handleClick(item?.redirect);
                    navigate(`/pro-advantage#${item?.redirect}`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div class="pro-advantage-box">
                    <img
                      src={item?.img}
                      alt=""
                      style={{ height: "150px", width: "175px" }}
                    />
                    <h4 style={{ fontSize: "28px" }}>{item?.title}</h4>
                    <p
                      className={
                        item?.description?.split("\n").length > 10
                          ? "limit-lines"
                          : ""
                      }
                    >
                      {limitWords(item?.description, 225)}
                    </p>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
      {/* <!-- Pro Advantages Section End --> */}

      {/* <!-- Pro Advantages Section Start --> */}
      <section class="pro-advantage news_press_sec" id="pro-news">
        <h2 class="content-title mb-5">Our Team</h2>
        <Slider {...newsNpress} className="p-0 row  news_press_slider">
          {team?.map((item, index) => {
            return (
              <div class="col-lg-3" key={index}>
                <div class="news_box_main">
                  <div class="new_img_box">
                    <img src={item?.avatar} alt="" className="team-images" />
                  </div>
                  <div class="content_new" style={{ padding: "0px 20px" }}>
                    <h3
                      style={{
                        paddingBottom: "0px !important",
                        fontSize: "20px",
                      }}
                    >
                      {item?.firstName + " " + item?.lastName}
                    </h3>
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "lightblue",
                      }}
                    >
                      {item?.designation}
                    </p>
                    {/* <p>{item?.description}</p> */}
                    {/* <a
                      href="#"
                      onClick={() =>
                        navigate(`/news/${item.id}`, {
                          state: {
                            slug: item.slug,
                          },
                        })
                      }
                    >
                      Learn More<i class="bx bx-right-arrow-alt"></i>
                    </a> */}
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
        <div class="pt-5  d-flex align-items-center justify-content-center">
          <a href="#" class=" themeBtn " onClick={() => navigate("/team")}>
            View More
          </a>
        </div>
      </section>
      {/* <!-- Pro Advantages Section End --> */}

      {/* <!-- Pro Advantages Section Start --> */}
      <section class="pro-advantage">
        <h2 class="content-title mb-5">Our Members</h2>
        <div class="container">
          <ul className="partners_logos brand-slider">
            <Slider {...membersSetting} style={{ width: "300px !important" }}>
              {members
                ?.sort((a, b) => 0.5 - Math.random())
                ?.map((item, index) => {
                  return (
                    <li
                      class="member-logo"
                      key={index}
                      onClick={() =>
                        window.open(
                          `https://app.prolognet.com/profile/${item.companyId}`,
                          "_blank"
                        )
                      }
                      style={{
                        cursor: "pointer",
                        height: "200px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={item.image}
                        alt=""
                        style={{
                          marginBottom: "10px",
                          maxHeight: "75px",
                          objectFit: "contain",
                        }}
                      />
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#fff",
                          marginTop: "5px",
                        }}
                      >
                        {item?.country}
                      </p>
                    </li>
                  );
                })}
            </Slider>
          </ul>
        </div>
      </section>
      {/* <!-- Pro Advantages Section End --> */}

      {/* <!-- Pro Advantages Section Start --> */}
      <section class="pro-advantage news_press_sec" id="our-partner">
        <h2 class="content-title mb-5">Our Partners</h2>
        <div class="container">
          <ul className="partners_logos brand-slider">
            <Slider {...partnersSetting}>
              {partners.map((item, index) => {
                return (
                  <li
                    style={{
                      justifyContent: isMobile && "center !important",
                    }}
                    key={index}
                    onClick={() => window.open(`${item.link}`, "_blank")}
                  >
                    <img
                      src={item.image}
                      alt=""
                      style={{ cursor: "pointer" }}
                    />
                  </li>
                );
              })}
            </Slider>
          </ul>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
